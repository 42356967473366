import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';

import { SetStudentAction } from './student.action';

export interface StudentStateModel {
  id: number | null;
  name: string | null;
  image: string | null;
  token: string | null;
}

const STUDENT_STATE_TOKEN = new StateToken<StudentStateModel>('student');

@State<StudentStateModel>({
  name: STUDENT_STATE_TOKEN,
  defaults: {
    id: null,
    name: null,
    image: null,
    token: null
  }
})
@Injectable()
export class StudentState {
  @Selector() static student(student: StudentStateModel) {
    return student;
  }

  @Selector() static token({ token }: StudentStateModel) {
    return token;
  }

  @Selector() static studentName({ name }: StudentStateModel) {
    return name;
  }

  @Action(SetStudentAction) setStudent(
    { patchState }: StateContext<StudentStateModel>,
    { payload }: SetStudentAction
  ): void {
    patchState({ ...payload });
  }
}
