import { Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Store } from '@ngxs/store';
import { of } from 'rxjs';
import { register } from 'swiper/element/bundle';

import { ConfigService } from './core/services/config.service';
import { WindowEventsService } from './core/services/window-events.service';
import {
  SaveActivityTimeAction,
  SetStartActivityTimeAction,
  UpdateLastActivityTimeAction
} from './core/store/activity/activity.actions';

// register Swiper custom elements
register();

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Liv Portfolio - Família';

  readonly config$ = of(this.configService.config);

  constructor(
    private configService: ConfigService,
    private store: Store,
    private windowEvents: WindowEventsService
  ) {
    this.windowEvents.combinedEvents$
      .pipe(takeUntilDestroyed())
      .subscribe((event) => {
        if (typeof event === 'boolean') {
          this.handleVisibilityChange(event);
        } else {
          this.handleUnloadEvent();
        }
      });
  }

  private handleVisibilityChange(isTabHidden: boolean): void {
    if (isTabHidden) {
      this.handleTabHidden();
    } else {
      this.handleTabVisible();
    }
  }

  private handleTabVisible(): void {
    this.store.dispatch(new SetStartActivityTimeAction());
  }

  private handleTabHidden(): void {
    this.store.dispatch([
      new UpdateLastActivityTimeAction(),
      new SaveActivityTimeAction('visibility')
    ]);
  }

  private handleUnloadEvent(): void {
    this.store.dispatch(new SaveActivityTimeAction('unload'));
  }
}
