import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideHotToastConfig } from '@ngneat/hot-toast';
import { NgxsStoragePluginModule, StorageOption } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { decrypt, encrypt } from './core/security/state-cypher';
import { ConfigService } from './core/services/config.service';
import { StudentState } from './features/cycles/store/student/student.state';
import { StudentSchoolsState } from './features/cycles/store/student-schools/student-schools.state';

export const configFactory = (configService: ConfigService) => {
  return () => configService.loadConfig$();
};
const PERSISTED_STATES = [StudentState];
const STATES = [...PERSISTED_STATES, StudentSchoolsState];

@NgModule({
  declarations: [AppComponent],
  providers: [
    ConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: configFactory,
      deps: [ConfigService],
      multi: true
    },
    provideHotToastConfig()
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CoreModule,
    AngularSvgIconModule,
    NgxsModule.forRoot(STATES, {
      selectorOptions: {
        injectContainerState: false //https://www.ngxs.io/advanced/options (injectContainerState false é recomendado p/ melhor performance)
      }
    }),
    NgxsStoragePluginModule.forRoot({
      storage: StorageOption.SessionStorage,
      namespace: '@liv-cicles',
      keys: [...PERSISTED_STATES],
      serialize: encrypt,
      deserialize: decrypt
    })
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
