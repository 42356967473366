import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';

import { SetStudentSchoolsAction } from './student-schools.action';
import { GradeLivModel } from '../../models/grade.model';
import { SchoolModel } from '../../models/school.model';

export interface StudentSchoolsStateModel {
  studentIds: number[] | [];
  studentSchools: SchoolModel[] | [];
  grades: GradeLivModel[] | [];
}

const STUDENT_SCHOOLS_STATE_TOKEN = new StateToken<StudentSchoolsStateModel>(
  'studentschool'
);

@State<StudentSchoolsStateModel>({
  name: STUDENT_SCHOOLS_STATE_TOKEN,
  defaults: {
    studentIds: [],
    studentSchools: [],
    grades: []
  }
})
@Injectable()
export class StudentSchoolsState {
  @Selector() static studentschool(studentSchool: StudentSchoolsStateModel) {
    return studentSchool;
  }
  @Selector() static schools({ studentSchools }: StudentSchoolsStateModel) {
    return studentSchools;
  }
  @Selector() static grades({ grades }: StudentSchoolsStateModel) {
    return grades;
  }

  @Selector() static studentIds({ studentIds }: StudentSchoolsStateModel) {
    return studentIds;
  }

  @Action(SetStudentSchoolsAction) setStudentSchool(
    { patchState }: StateContext<StudentSchoolsStateModel>,
    { payload }: SetStudentSchoolsAction
  ): void {
    patchState({ ...payload });
  }
}
